import React from 'react'
import styled from 'styled-components'
import { bool } from 'prop-types'
import Link from 'gatsby-link'

import Logo from '../../Atoms/Logo'
import NewFooterLogo from '../../Atoms/2024-Footer-Logo/2024-Footer-Logo'

const MenuCon = styled.nav`
  display: flex;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  background: ${p => p.theme.color.white};
  transform: ${p => p.open ? 'translateX(0)' : 'translateX(100%)'};
  height: 100vh;
  min-height: 600px;
  text-align: right;
  align-items: flex-end;
  position: fixed;
  top: 0;
  right: 0;
  padding: 2.5rem;
  transition: transform 0.5s ease-in-out;
  ${p => p.theme.mediaQueries.smallDown}{
    width: 100%;
  }
  ul{
    list-style-type: none;
  }
  li{
    font-family: ${p => p.theme.font.objectives};
    padding: 10px 0;
    // uncomment out to add styling for progress report
    // &:nth-child(5){
    //   padding-bottom: 20px;
    // }
    // &:last-child{
    //   border-top: .5px solid #a6a6a6;
    //   padding: 20px 0;
    //   a{
    //     color: #231f20;
    //   }
    // }
    .hideDesktop{
      ${p => p.theme.mediaQueries.ipadUp}{
        display: none;
      }
    }
  }
  a{
    text-decoration: none;
    color: ${p => p.theme.color.burgundy};
    font-size: 1.5625rem;
    transform: 0.3s ease;
    letter-spacing: 0.04em;
    &:hover{
      opacity: 0.75;
    }
    ${p => p.theme.mediaQueries.ipadUp}{
      font-size: 1.75rem;
    }
    ${p => p.theme.mediaQueries.hugeUp}{
      font-size: 2rem;
    }
  }
  ${p => p.theme.mediaQueries.ipadUp}{
    padding-left: 5rem;
    padding-right: 5rem;
  }
  ${p => p.theme.mediaQueries.hugeUp}{
    padding-left: 9rem;
    padding-right: 8rem;
  }
`

const MenuList = styled.ul`
  padding-bottom: 3rem;
`


const NavMenu = ({open, setOpen}) => {
  return(
    <MenuCon open={open}>
      <MenuList>
        <li ><Link to="/#download" className="hideDesktop" onClick={() => setOpen(!open)}>Download the Plan</Link></li>
        <li><Link to="/#mission" onClick={() => setOpen(!open)}>Our Mission</Link></li>
        <li><Link to="/#approach" onClick={() => setOpen(!open)}>Our Approach</Link></li>
        <li><Link to="/#process" onClick={() => setOpen(!open)}>Our Process</Link></li>
        <li><Link to="/#objectives" onClick={() => setOpen(!open)}>Our Objectives</Link></li>
        <li><Link to="/#commitment" onClick={() => setOpen(!open)}>Our Commitment</Link></li>
        {/*Uncomment final item to add progress report to nav*/}
        <li><Link to="/#progress" onClick={() => setOpen(!open)}>View Progress <br />Report</Link></li>
      </MenuList>
      <NewFooterLogo style="padding-top: 3rem;"/>
    </MenuCon>
  )
}
NavMenu.propTypes = {
  open: bool.isRequired,
}

export default NavMenu;