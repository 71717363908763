import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from'gatsby'

import Img from 'gatsby-image'


const FooterLogoTwo = styled(Img)`
  width: 150px;
`

const NewFooterLogo = () => {
  const image = useStaticQuery(graphql`
    query footerImageQuery {
      footerlogo2: file(relativePath: {eq: "cc-burgundy.png"}, 
        sourceInstanceName: {eq: "images"}
      ){
        id
        childImageSharp{
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `
  )
  return(
    <FooterLogoTwo fixed={image.footerlogo2.childImageSharp.fixed} alt="Cambrian College logo" loading="eager"/>
  )
}

export default NewFooterLogo;