import React from 'react'
import styled from 'styled-components'
import { bool, func } from 'prop-types';

const HamburgerButton = styled.button`
  z-index: 2;
  width: 30px;
  height: 26px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  &:focus{
    outline: none;
  }
  &:hover{
    opacity: 0.75;
  }
  span{
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: ${p => p.theme.color.white};
    border-radius: 4px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    background: ${p => (p.open ? p.theme.color.burgundy : p.theme.color.white)};
  }
  span:nth-child(1){
    ${p => (p.open ? 'top: 0px; width: 0%; left: 50%;' : 'top: 0px;')};
  }
  span:nth-child(2){
    top: 10px;
    ${p => p.open ? 'transform: rotate(45deg);' : ''}
  }
  span:nth-child(3){
    top: 10px;
    ${p => p.open ? 'transform: rotate(-45deg);' : ''}
  }
  span:nth-child(4){
    ${p => p.open ? 'top: 10px; width: 0%; left: 50%;' : 'top: 20px;'};
  }
`

const Hamburger = ({open, setOpen}) => {
  return(
    <HamburgerButton type="button" tabindex="0" aria-label="Menu" aria-controls="navigation" open={open} onClick={() => setOpen(!open)}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </HamburgerButton>
  )
}
Hamburger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Hamburger