import React, {useState, useRef} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from'gatsby'

import { ThemeProvider } from 'styled-components'
import GlobalStyle from '../styles/globalStyles'
import styleSettings from '../styles/styleSettings'

import Header from '../components/Organisms/Header'
import Footer from '../components/Organisms/Footer'

import { useOnClickOutside } from '../hooks';

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [open, setOpen] = useState(false);
  const node = useRef(); 
  useOnClickOutside(node, () => setOpen(false));
  return (
    <>
      <ThemeProvider theme={styleSettings}>
        <Header siteTitle={data.site.siteMetadata.title} open={open} setOpen={setOpen} node={node}/>
          <GlobalStyle open={open} setOpen={setOpen}/>
          <main>{children}</main>
        <Footer />
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
