import React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'

import Logo from '../../Atoms/Logo'
import NewLogo from '../../Atoms/2024-Logo/2024-Logo'

import Hamburger from '../../Atoms/Hamburger'
import NavMenu from '../../Molecules/NavMenu'

const StyledHeader = styled.header`
  background-color: transparent;
  z-index: 1;
  margin: 0 auto;
  background-color: #591A2A;
`
const WhiteLogo = styled(Logo)`
  .prefix__cls-1{
    fill: ${p => p.theme.color.white};
  }
  width: 150px;
  ${p => p.theme.mediaQueries.largeUp}{
    width: 200px;
  }
`
const HeaderCon = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 2.5rem 2.5rem 0 2.5rem;
  ${p => p.theme.mediaQueries.hugeUp}{
    padding-left: 4rem;
    padding-right: 4rem;
  }
`
const Left = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  .hideMobile{
    display: none;
    text-decoration: none;
    color: ${p => p.theme.color.white};
    font-size: 1.125rem;
    font-family: ${p => p.theme.font.title};
    transition: 0.3s all ease-in-out;
    padding: 3px 14px 0 14px;
    &:hover{
    opacity: .75;
      }
      &:active, &:visited{
        color: ${p => p.theme.color.white};
      }
    &.gold{
      color: ${p => p.theme.color.gold};
      margin-right: 14px;
      &:active, &:visited{
        color: ${p => p.theme.color.gold};
      }
    }
    ${p => p.theme.mediaQueries.ipadUp}{
      display: inline;
    }
  }
`

function Header({open, setOpen, node}){
  return(
    <StyledHeader>
    <HeaderCon>
        <a href="https://cambriancollege.ca/" target="_blank" rel="noopener noreferrer">
          <NewLogo />
        </a>
        <Left>
          <Link to="/#objectives" className="hideMobile"><strong>view</strong> objectives</Link>
          <Link to="/#download" className="hideMobile gold" ><strong>download</strong> the plan</Link>
          <div ref={node}>
            <Hamburger open={open} setOpen={setOpen}/>
            <NavMenu open={open} setOpen={setOpen}/>
          </div>
        </Left>
    </HeaderCon>
  </StyledHeader>
  )
}

export default Header
