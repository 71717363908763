import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    *{
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    html{
        width: 100%;
        max-width: 100%;
        overflow-x: hidden;
        -webkit-font-smoothing: antialiased;
        font-smoothing: subpixel-antialiased;
        text-rendering: geometricPrecision;
        overflow: ${p => p.open ? 'hidden' : 'auto'};
    }
    h1, h2, h3, h4, h5, h6{
        -webkit-hyphens: none;
        -ms-hyphens: none;
        hyphens: none;
    }
    @media print {
        body {
          font-size: 12px;
        }
      }
      @font-face {
        font-family: 'Calibre';
        src: url('/fonts/calibre/CalibreWeb-Medium.woff2') format('woff2'),
          url('/fonts/calibre/CalibreWeb-Medium.woff') format('woff');
        font-weight: medium;
        font-style: normal;
      }
      @font-face {
        font-family: 'Calibre';
        src: url('/fonts/calibre/CalibreWeb-MediumItalic.woff2') format('woff2'),
          url('/fonts/calibre/CalibreWeb-MediumItalic.woff') format('woff');
        font-weight: medium;
        font-style: italic;
      }
`
export default GlobalStyle