//Container component without flex
import styled from 'styled-components'

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${p => p.theme.breakpoints.huge}px;
  padding: 3rem 1.5rem;
  ${p => p.theme.mediaQueries.ipadUp}{
    padding: 4rem 2rem;
  }
  ${p => p.theme.mediaQueries.largeUp}{
    padding: 5rem 2.25rem;
  }
`
export default Container