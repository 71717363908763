import buildMediaQueries from './buildMediaQueries'

//breakpoints
const breakpoints = {
    minimum: 0,
    tiny: 400,
    small: 600,
    ipad: 750,
    medium: 800,
    large: 905,
    big: 950,
    huge: 1100,
    xl: 1160
  }
let mediaQueries = buildMediaQueries(breakpoints)
mediaQueries["print"] = `@media only print`

//colours
const color = {
    burgundy: "#702d3e",
    gold: "#ffc53d",
    pink: "#f38075",
    green: "#61b180",
    blue: "#8ab8c1",
    white: 'white',
}

//fonts
const font = {
    body: "Asap, Arial, sans-serif",
    heading: "Calibre, Arial, sans-serif",
    objectives: "alternate-gothic-atf, Arial, sans-serif",
    title: "Helvetica, Arial, sans-serif"
}

//Exports
const defaultExport = {
    breakpoints,
    color,
    font,
    mediaQueries,
  }

export { defaultExport as default, breakpoints, color, font, mediaQueries } 