import React from 'react'
import styled from 'styled-components'

import Container from '../../Atoms/Container'
import Logo from '../../Atoms/Logo'
import NewFooterLogo from '../../Atoms/2024-Footer-Logo'

const FooterCon = styled.footer`
  background-color: ${p => p.theme.color.white};
  text-align: center;
  font-family: ${p => p.theme.font.heading};
  padding: 40px;
  p, a{
    color: ${p => p.theme.color.burgundy};
  }
  p{
    padding-top: 20px;
  }
`
const Top = styled.a`
  display: block;
  padding-top: 20px;
  text-decoration: none;
  font-weight: bold;
`
const FooterLogo = styled(Logo)`
  width: 150px;
`
const Footer = () => {
  return(
    <FooterCon>
      <Container>
        <a href="https://cambriancollege.ca/" target="_blank" rel="noopener noreferrer" aria-label="Cambrian College Main Site"><NewFooterLogo /></a>
        <p>Have questions? Ready to get involved? <strong><a href="mailto:stratplan2025@cambriancollege.ca">Email us</a>.</strong></p>
        <Top href="#top">Back to Top</Top> 
      </Container>
    </FooterCon>
  )
}

export default Footer