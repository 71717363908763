import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from'gatsby'

import Img from 'gatsby-image'


const UpdatedLogo = styled(Img)`
  width: 150px;
  ${p => p.theme.mediaQueries.largeUp}{
    width: 200px;
  }
`

const NewLogo = () => {
  const image = useStaticQuery(graphql`
    query imageQuery {
      logo2: file(relativePath: {eq: "cc-logo-white.png"}, 
        sourceInstanceName: {eq: "images"}
      ){
        id
        childImageSharp{
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `
  )
  return(
    <UpdatedLogo fixed={image.logo2.childImageSharp.fixed} alt="Cambrian College logo" loading="eager"/>
  )
}

export default NewLogo;